<template>
  <div class="spin">
    <div class="spin-title">
      {{this.title}}
    </div>
    <div class="spin-edit">
        <button type="button" class="btn border-left-0 border-dark ctrbutton btn-spin-left" @click="change(false)">-</button>
        <button type="button" class="btn border-left-0 border-dark valuebutton btn-spin" @click="setValue()">{{value}}</button>
        <button type="button" class="btn border-left-0 border-dark ctrbutton btn-spin-right" @click="change(true)">+</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'spin-edit',
  data() {
    return {
    };
  },
  props: {
    title: String,
    type: String,
  },
  components: {},
  computed: {
    value(){
      if (this.type === 'Gebot'){
        return this.$store.state.newgebot
      } else {
        return this.$store.state.newlos
      }
    }
  },
  methods: {
    setValue() {
      if (this.type === 'Gebot'){
      this.$emit('setvalue', this.$store.state.newgebot);
      } else {
      this.$emit('setvalue', this.$store.state.newlos);
      }
    },
    change(dir) {
      if (this.type === 'Gebot'){
        if (dir) {
          this.$store.commit('SET_NEWGEBOT', 100)
        } else {
          this.$store.commit('SET_NEWGEBOT', -100)
        }
      } else {
        if (dir) {
          this.$store.commit('SET_NEWLOS', 1)
        } else {
          this.$store.commit('SET_NEWLOS', -1)
        }
      }
    }
  }
  
}
</script>

<style scoped>
@import '../../scss/classes.scss';

.spin{
  width: 30%;
}

.spin-title{
  width: 100%;
  height: 20%;
  font-size: 30pt;
}
.spin-edit{
  width: 100%;
  height: 80%;
  padding: 5px;
}
.valuebutton{
  font-size: 50pt;
  width: 50%;
  height: 100%;
}

</style>
