<template>
  <div class="hv100">
  <Keypress key-event="keydown" :multiple-keys="arrowdown"  @success="setsaal" />
  <Keypress key-event="keydown" :multiple-keys="arrowup"  @success="setonline" />
  <Keypress key-event="keydown" :multiple-keys="point"  @success="auktionatorstep" />
  <Keypress key-event="keydown" :multiple-keys="personone"  @success="showkey" />
  <Keypress key-event="keydown" :multiple-keys="persontwo"  @success="showkey" />
  <Keypress key-event="keydown" :multiple-keys="persontree"  @success="showkey" />
    <div class="visualwrapper" :class="{newgebot: newgebot}">
      <buyer />
      <cowinfo />
    </div>
    <div v-if="newgebot" class="anfrage">
      Onlinebieter:<br>
      <h1>{{onlineuser}}</h1>
      {{userort}}
    </div>
    <div class="controlwrapper">
      <spin-edit title="Neues Gebot" @setvalue="setgebot" type="Gebot"/>
      <spin-edit title="Auktionsnr" @setvalue="setauktionsnr" type="Los"/>
      <div class="controlbuttonwrapper">
        <div class="buttonsspacer">
          Steuerung
        </div>
        <div class="buttonswrapper">
          <button type="button" class="btn border border-dark prev" @click="prevstep()">Zurück</button>
          <button type="button" class="btn border border-dark ctrbutton next" @click="nextstep()">Weiter</button>
          <div class="smallbuttonwrapper">
            <button type="button" class="btn border border-dark small" @click="setsaal()" :disabled="auctionatorvalue=='Verkauft' || auctionatorvalue=='Eröffnen'">Zusage Saal</button>
            <button type="button" class="btn border border-dark small" @click="setonline()" :disabled="!newgebot">Zusage online</button>
            <button type="button" class="btn border border-dark small" @click="nextpricestep()">{{nextprice}}</button>
            <button type="button" class="btn border border-dark small" @click="auktionatorstep()" :disabled="auctionatorvalue=='Verkauft'">{{auctionatorvalue}}</button>
          </div>
          <div class="smallbuttonwrapper">
            <button type="button" class="btn border border-dark small" @click="closeaukt()" >Schliessen</button>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" title="Weiter">
    <p class="my-4">Dieses Los wurde noch nicht verkauft, wollen Sie trotzdem weiter?</p>
    <template #modal-footer="{}">
      
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="xl" @click="saleandnext()">
        Los verkaufen
      </b-button>
      <b-button size="xl" @click="nextwosale()">
        Los nicht verkaufen und weiter
      </b-button>
      <!-- Button with custom close trigger value -->
    </template>
  </b-modal>
  </div>
</template>

<script>
import SpinEdit from './components/controls/SpinEdit.vue'
import Buyer from './components/visuals/buyer.vue';
import Cowinfo from './components/visuals/cowinfo.vue';
import { useSound } from '@vueuse/sound'
import alert from '@/assets/alert.mp3'

export default {
  name: 'App',
  components: {
    SpinEdit,
    Buyer,
    Cowinfo,
    Keypress: () => import('vue-keypress')
  },
  setup() {
    const { play } = useSound(alert)

    return {
      play
    }
  },
  data: () => ({
    arrowdown: [
      {
        keyCode: 40,
        modifiers: [],
        preventDefault: true
      }
    ],
    arrowup: [
      {
        keyCode: 38,
        modifiers: [],
        preventDefault: true
      }
    ],
    point: [
      {
        keyCode: 67,
        modifiers: [],
        preventDefault: true
      }
    ],
    personone: [
      {
        keyCode: 66,
        modifiers: ['ctrlKey'],
        preventDefault: true
      }
    ],
    persontwo: [
      {
        keyCode: 72,
        modifiers: ['ctrlKey'],
        preventDefault: true
      }
    ],
    persontree: [
      {
        keyCode: 85,
        modifiers: ['ctrlKey'],
        preventDefault: true
      }
    ]
  }),
  mounted() {
    if (this.$store.state.socket.isConnected){
        var adduser = {
          event: 'add saal',
        }
        this.$socket.sendObj(adduser);
      }
      this.$options.sockets.onopen = (data) => {
        console.log(data);
          var adduser = {
            event: 'add saal',
          }
          this.$socket.sendObj(adduser);
        }
      this.$options.sockets.onmessage = (data) => {
          var resp = JSON.parse(data.data);
          console.log(resp);
          if (resp.event == 'actualgebot'){
            this.isloading = false
            var jsongebot = resp.gebot;
            if (this.live){
              if (jsongebot.auktionvalue == 4 && jsongebot.saleuser.email == this.$store.getters.getUser.email && jsongebot.saleuser.email != undefined){
                this.showWin();
              }
            }
            this.$store.commit('SET_GEBOT',jsongebot);
          }
        }
  },
  computed: {
    actualgebot(){
      return this.$store.state.gebot.new
    },
    auctionatorvalue(){
      if (this.$store.state.gebot.auktionvalue === 0) return 'Eröffnen';
      if (this.$store.state.gebot.auktionvalue === 1) return 'Zum 1.';
      if (this.$store.state.gebot.auktionvalue === 2) return 'Zum 2.';
      if (this.$store.state.gebot.auktionvalue === 3) return 'Zum 3.';
      if (this.$store.state.gebot.auktionvalue === 4) return 'Verkauft';
      return '';
    },
    nextprice(){
      if ((this.$store.state.gebot.new - this.$store.state.gebot.act)===50) return 50
      return 100
    },
    onlineuser(){
      return this.$store.state.gebot.onlineuser.vorname + " " + this.$store.state.gebot.onlineuser.nachname;
    },
    userort(){
      return this.$store.state.gebot.onlineuser.plz + " " + this.$store.state.gebot.onlineuser.ort;
    },
    newgebot(){
      if (this.$store.state.gebot.anfrage > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    nextwosale() {
      var gebot = {
          event: 'set los',
          value: this.$store.state.gebot.auktnr + 1
        }
      this.$socket.sendObj(gebot);
        this.$bvModal.hide('modal-1');
    },
    saleandnext() {
      var auktionv = {
          event: 'set auctionatorstep',
          value: 4
        }
        this.$socket.sendObj(auktionv);
      var gebot = {
          event: 'set los',
          value: this.$store.state.gebot.auktnr + 1
        }
      this.$socket.sendObj(gebot);
      this.$bvModal.hide('modal-1');
    },
    nextpricestep(){
      if ((this.$store.state.gebot.new - this.$store.state.gebot.act)===50) {
        if (this.$store.state.socket.isConnected){
        var nextstep = {
          event: 'next step',
          value: 100
        }
        this.$socket.sendObj(nextstep);
      }
      } else {
        nextstep = {
          event: 'next step',
          value: 50
        }
        this.$socket.sendObj(nextstep);
      }
    },
    showkey(){
      window.event.preventDefault();
      this.nextpricestep();
    },
    auktionatorstep(){
      if (this.$store.state.gebot.auktionvalue < 4){
        var auktionv = {
          event: 'set auctionatorstep',
          value: this.$store.state.gebot.auktionvalue + 1
        }
        this.$socket.sendObj(auktionv);
      }
    },
    closeaukt(){
        var auktionv = {
          event: 'set auctionatorstep',
          value: 0
        }
        this.$socket.sendObj(auktionv);
    },
    setsaal(){
      if (this.$store.state.gebot.auktionvalue < 4 && this.$store.state.gebot.auktionvalue != 0){
        var gebot = {
          event: 'set saal',
          price: this.$store.state.gebot.new
        }
        this.$socket.sendObj(gebot);
      }
    },
    setonline(){
      if (this.newgebot){
        var gebot = {
          event: 'set online',
          price: this.$store.state.gebot.onlinegebot,
          user: this.$store.state.gebot.onlineuser
        }
        this.$socket.sendObj(gebot);
      }
    },
    nextstep() {
      if (this.$store.state.gebot.auktionvalue < 4) {
        this.$bvModal.show('modal-1')
      } else {
       var gebot = {
          event: 'set los',
          value: this.$store.state.gebot.auktnr + 1
        }
        this.$socket.sendObj(gebot);
      }  
    },
    prevstep() {
       var gebot = {
          event: 'set los',
          value: this.$store.state.gebot.auktnr - 1
        }
        this.$socket.sendObj(gebot);
    },
    setauktionsnr(value) {
       var gebot = {
          event: 'set los',
          value: value
        }
        this.$socket.sendObj(gebot);
    },
    setgebot(value) {
      var gebot = {
          event: 'set saal',
          price: value
        }
        this.$socket.sendObj(gebot);
    },
    setschatz(value) {
      var gebot = {
          event: 'set schatzprice',
          value: value
        }
        this.$socket.sendObj(gebot);
    },
  },
  watch: {
    newgebot(gebotn){
      if (gebotn) {
        this.play();
      }
    }
  }
}
</script>
<style>
@import '/scss/classes.scss';
.visualwrapper{
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  height: 55%;
}
.controlbuttonwrapper{
  height: 100%;
  width: 50%;
}
.buttonsspacer{
  height: 20%;
  font-size: 30pt;
}
.anfrage{
  background-color: rgb(100,255,100);
  height: 20%;
  font-size: 40px;
  text-align: center;
}
h1{
  font-size: 60px;
}
.small{
  height: 50%;
  width: 50%;
  font-size: 30pt;
  background-color: rgb(240, 240, 255) !important;
}
.hv100{
  height: 100vh;
}
.smallbuttonwrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 65%;
}

.buttonswrapper{
  height: 80%;
  width: 100%;
  padding: 5px;
  display: flex;
}

.newgebot{
  background-color: rgb(100,255,100);
}

.next{
  height: 100%;
  width: 35%;
}
.prev{
  height: 100%;
  width: 10%;
}
.controlwrapper{
  width: 100%;
  height: 25%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  margin: 0px;
  padding: 0px;
  display: flex;
}
</style>
