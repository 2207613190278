<template>
  <div class="buyer">
    <h4>aktuelles Gebot: </h4><h1>{{ actgebot }}.- SFr.</h1>
    <h4>neues Gebot: </h4><h3>{{ newgebot }}.- SFr.</h3><br>
    <h2>{{ username }}</h2>
    {{ email }}<br>
    {{ adresse }}<br>
    {{ ort }}
    
  </div>
</template>

<script>

export default {
  name: 'buyer',
  data() {
    return {
    };
  },
  props: {
  },
  components: {},
  computed: {
    actgebot(){
        return this.$store.state.gebot.act
    },
    newgebot(){
        return this.$store.state.gebot.new
    },
    username(){
        return this.$store.state.gebot.saleuser.vorname + " " + this.$store.state.gebot.saleuser.nachname
    },
    email(){
        return this.$store.state.gebot.saleuser.email
    },
    adresse(){
        return this.$store.state.gebot.saleuser.adresse + " " + this.$store.state.gebot.saleuser.nr 
    },
    ort(){
        return this.$store.state.gebot.saleuser.plz + " " + this.$store.state.gebot.saleuser.ort
    }
  },
  
}
</script>

<style scoped>
@import '../../scss/classes.scss';

.buyer{
  margin: 50px;
  font-size: 50px;
}

h1{
    font-size: 80px;
    color: red;
  }
h2{
    font-size: 70px;
  }
h3{
    font-size: 50px;
  }


</style>
